import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';

const poolData = {
  UserPoolId: 'sa-east-1_AVgrPS36J',
  ClientId: '10odkcva9dtnabu1bvmc31m4jo',
  Storage: new CookieStorage({
    secure: false,
    domain: window.location.host.replace('account.', '').replace('account.', '').replace(':3000', ''),
  }),
};

export default new CognitoUserPool(poolData);
