import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CognitoUser,
  AuthenticationDetails,
  CookieStorage,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import Pool from '../auth/UserPool';
import { jwtDecode } from "jwt-decode";

export const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [session, setSessionContext] = useState(false);

useEffect(() => {
    getSession()
      .then((session) => {
        setSessionContext(session);
      })
      .catch(() => null);
  }, []);
  
  
 useEffect(() => {
    const getCodeFromUrl = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('code');
    };
    const code = getCodeFromUrl();
    if (code) {
      fetch('https://trii-admin.auth.sa-east-1.amazoncognito.com/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          client_id:'10odkcva9dtnabu1bvmc31m4jo',
          redirect_uri: 'http://localhost:3000',
          code:code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Tokens obtenidos:', data);
          const decodedToken = jwtDecode(data.id_token);
          
          const cognitoUser = new CognitoUser({
            Username:decodedToken.email,
            Pool: Pool,
            Storage: new CookieStorage({
              secure: false,
              domain: window.location.host.replace('account.', '').replace(':3000', '').replace(':3001', ''),
            }),
          });
          const idToken = new CognitoIdToken({ IdToken: data.id_token });
          const accessToken = new CognitoAccessToken({ AccessToken: data.access_token });
          const refreshToken = new CognitoRefreshToken({ RefreshToken: data.refresh_token });
          const sessionData = {
            IdToken: idToken,
            AccessToken: accessToken,
            RefreshToken: refreshToken
          };
          
          cognitoUser.setSignInUserSession(new CognitoUserSession(sessionData));
          
          window.location.href='/'
        })
        .catch((error) => {
          console.error('Error al obtener los tokens:', error);
        });
    } else {
      console.error('No se encontró ningún código en la URL');
    
    }}, []);
  
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    // user.getDe;
    
    if (user) {
      user.signOut();
    }
    setSessionContext(false);
  };
 
  const setUser = (Username) => {
    return new CognitoUser({
      Username,
      Pool,
      Storage: new CookieStorage({
        secure: false,
        domain: window.location.host.replace('account.', '').replace(':3000', '').replace(':3001', ''),
      }),
    });
  };

  const resendVerificationCode = (email) => {
    const user = setUser(email);

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.log(err);
      } else {
        console.log(
          'Resend verification code Success: ' + JSON.stringify(result),
        );
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        //authenticate,
        getSession,
        logout,
        session,
        setSessionContext,
        setUser,
        resendVerificationCode,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountProvider;
