import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useField from 'hooks/useField';
import { SignInFlow } from './components';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import { Main } from 'components/layouts';
import Button from '@mui/material/Button';


export default function SignIn() {
  const [fadeIn, setFadeIn] = useState(false);
  const { t } = useTranslation();
  const [stage, setStage] = useState('signIn');

  useEffect(() => {
    setFadeIn(true);
  }, []);
  
  const domain = `https://trii-admin.auth.sa-east-1.amazoncognito.com`; 
  const clientId = '10odkcva9dtnabu1bvmc31m4jo'; 

  const loginUrl = `${domain}/login?client_id=${clientId}&response_type=code&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A3000`;

  const handleLogin = () => {
    window.location.href = loginUrl;
  };


  return (
    <Main>
      <Box bgcolor={'alternate.main'}>
        <Fade in={fadeIn}>
          <Container
            style={{
              maxWidth: 800,
              height: '85vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box marginBottom={4} display={"flex"} >
              <Box component={'img'} src={'/img/Trii.svg'} sx={{marginRight: "15px"}}
          height={100}
          ></Box>
              <Box>

                <Typography
                  sx={{
                    textTransform: 'uppercase',
                  }}
                  gutterBottom
                  color={'text.secondary'}
                  fontWeight={700}
                >
                  {t('global.login')}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {t('login.welcomeBack')}
                </Typography>
                <Typography color="text.secondary">
                  {stage === 'signIn' &&
                    t('login.logInToManageAccount')}
                  {stage === 'emailVerification' &&
                    t('verify.verifyYourEmail')}
                </Typography>
              </Box>
            </Box>
           
            <Button
              style={{ minWidth: 140 }}
              size={'large'}
              variant={'contained'}
              onClick={handleLogin}
            >
              {t('global.login')}
            </Button>
          </Container>
        </Fade>
      </Box>
    </Main>
  );
}
